<template>
<div>

  <div id="main" class="site-main">

  <div id="main-content" class="main-content">

  <div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style="">  Фото</h1></div></div></div>
  	<div class="block-content">
  		<div class="container">
  			<div class="panel row panel-sidebar-position-right with-sidebar">

  <newsblockUniversal :category="fotocategory" count=6 />

  				<div class="sidebar col-lg-3 col-md-3 col-sm-12" role="complementary"><div class="widget-area">

<searchbar />

  <calendar />
      <rubricator position="foto"/>
<tagscloud/>
</div>
  </div><!-- .sidebar -->			</div>
  		</div><!-- .container -->
  	</div><!-- .block-content -->
  </div><!-- #main-content -->

</div>
</div>
</template>

<script>
import categories from "@/lib/categories.js"
import rubricator from "@/components/rubricator.vue"
import tagscloud from "@/components/tagscloud.vue"
import newsblockUniversal from "@/components/news/newsblock-universal.vue"
import searchbar from "@/components/searchbar.vue"
import calendar from "@/components/calendar.vue"
import {rollup} from "@/lib/utils.js"
export default {
name : 'Foto',
components : {tagscloud, newsblockUniversal,rubricator, searchbar, calendar} ,
mounted() {
rollup()
console.log(this.$route.params)

},
data() {
 return {
   fotocategory : categories.foto
 }

},
watch : {
$route() {
  rollup()
}

}

}
</script>
